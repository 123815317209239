<template>
  <Layout>
    <PageHeader
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        :title="$t('tree.title')"
    />
    <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="nav-tabs-custom"
    >
      <b-tab active>
        <template v-slot:title>
          <div @click="getTree()" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("tree.table_view") }}
            </span>
          </div>
        </template>
        <div>
<!--          <div class="w-25 mb-1 mb-sm-3">-->
<!--            <label class="form-label">Branch</label>-->
<!--            <select class="form-select" v-model="branch_id" @change="getTree">-->
<!--              <option value="1">Kassala</option>-->
<!--              <option value="2">Al-Girba</option>-->
<!--              <option value="3">Halfa</option>-->
<!--            </select>-->
<!--          </div>-->
          <input type="file" class="d-none" id="read-tree">

          <button class="btn btn-info float-end" @click="readTree">{{ $t("tree.import") }}</button>
          <button class="btn btn-success float-end" @click="exportCOA()">{{ $t("tree.export") }}</button>
          <button
              @click="account_data={}"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#add-account"
              class="btn btn-primary mb-4 float-end"
          >
            {{ $t("tree.add_account") }}
          </button>
          <table
              style="margin-top: 29px"
              class="table"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
            <tr
                class="text-light"
                style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("tree.account_name_en") }}</th>
              <th scope="col">{{ $t("tree.account_name") }}</th>
              <th scope="col">{{ $t("tree.code") }}</th>
              <th scope="col">{{ $t("tree.type") }}</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                :title="account.level==0?$t('tree.level_one'):account.level==1?$t('tree.level_two'):account.level==2?$t('tree.level_three'):$t('tree.level_four')"
                :class="{'tree-level-zero':(account.level==0),'tree-level-one':(account.level==1),'tree-level-two':(account.level==2),'tree-level-three':(account.level==3)}"
                v-for="account in table_data"
                :key="account"
            >
              <td scope="col">{{ account?.name_en }}</td>
              <td scope="col">{{ account?.name }}</td>
              <td scope="col">{{ account.code }}</td>
              <td scope="col">{{ account.type }}</td>
              <td scope="col">
                <button v-if="account.level > 0" class="btn btn-success" data-bs-toggle="modal" @click="preEditAccount(account)" data-bs-target="#edit-account">{{$t("tree.edit")}}</button>
                <button v-if="account.level > 0" class="btn btn-danger" @click="deleteAccount(account)">{{$t("tree.delete")}}</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </b-tab>
      <b-tab >
        <template v-slot:title>
          <div @click="getTree()" type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
              >{{ $t("tree.chart_view") }}
            </span>
          </div>
        </template>
        <div>
<!--          <div class="w-25 mb-1 mb-sm-3">-->
<!--            <label class="form-label">Branch</label>-->
<!--            <select class="form-select" v-model="branch_id" @change="getTree">-->
<!--              <option value="1">Kassala</option>-->
<!--              <option value="2">Al-Girba</option>-->
<!--              <option value="3">Halfa</option>-->
<!--            </select>-->
<!--          </div>-->
          <div id="svg"></div>
        </div>
      </b-tab>
    </b-tabs>
    <!--Start Insert Modal-->
    <div
        class="modal fade"
        id="add-account"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="addAccount()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span>{{ $t("tree.add_account") }} </span>
                </h5>
              </div>
              <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("tree.account_element")}}</label>
                <select class="form-select" v-model="account_data.level_one">
                  <option :value="account" :key="account" v-for="account in chart_data">{{$i18n.locale == 'ar' ? account.inputs[0].value : account.inputs[1].value}}</option>
                </select>
              </div>

              <button type="button" v-if="account_data.level_one" @click="account_data.show_level_two = true" class="btn btn-sm btn-success">
                {{$t("tree.add_category")}}
              </button>
              <div class="mb-1 mb-sm-3" v-if="account_data.show_level_two">
                <label class="form-label">{{$t("tree.category")}}</label>
                <select class="form-select" v-model="account_data.level_two">
                  <option :value="account" :key="account" v-for="account in account_data.level_one.children">{{$i18n.locale == 'ar' ? account.inputs[0].value : account.inputs[1].value}}</option>
                </select>
              </div>
              <button type="button" v-if="account_data.level_two" @click="account_data.show_level_three = true" class="btn btn-sm btn-success">
                {{$t("tree.add_parent")}}
              </button>
              <div class="mb-1 mb-sm-3" v-if="account_data.show_level_three">
                <label class="form-label">{{$t("tree.parent")}}</label>
                <select class="form-select" v-model="account_data.level_three">
                  <option :value="account" :key="account" v-for="account in account_data.level_two.children">{{$i18n.locale == 'ar' ? account.inputs[0].value : account.inputs[1].value}}</option>
                </select>
              </div>

              <hr style="margin-top: 20px">

              <div class="mb-1 mb-sm-3" v-if="account_data.level_one">
                <label class="form-label">{{$t("tree.account_name_en")}}</label>
                <input type="text" class="form-control" v-model="account_data.name_en">
              </div>
              <div class="mb-1 mb-sm-3" v-if="account_data.level_one">
                <label class="form-label">{{$t("tree.account_name")}}</label>
                <input type="text" class="form-control" v-model="account_data.name">
              </div>
              <div class="mb-1 mb-sm-3" v-if="account_data.level_one">
                <label class="form-label">{{$t("tree.code")}}</label>
                <input type="text" class="form-control" v-model="account_data.code">
              </div>
              <button class="btn btn-success" data-bs-dismiss="modal" v-if="account_data.level_one">
                {{$t("tree.insert")}}
              </button>
            </div>


          </form>
        </div>
      </div>
    </div>
    <!--End Insert Modal-->

    <!--Start Edit Modal-->
    <div
        class="modal fade"
        id="edit-account"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <form @submit.prevent="editAccount()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span>{{ $t("tree.edit_account") }} </span>
                </h5>
              </div>
              <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">


              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("tree.account_name_en")}}</label>
                <input type="text" class="form-control" v-model="edit_data.name_en">
              </div>
              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("tree.account_name")}}</label>
                <input type="text" class="form-control" v-model="edit_data.name">
              </div>
              <div class="mb-1 mb-sm-3">
                <label class="form-label">{{$t("tree.code")}}</label>
                <input type="text" class="form-control" v-model="edit_data.code">
              </div>
              <button class="btn btn-success" data-bs-dismiss="modal">
                {{$t("tree.edit")}}
              </button>
            </div>


          </form>
        </div>
      </div>
    </div>
    <!--End Edit Modal-->


    <!--Start Tree Import Modal-->
    <button data-bs-toggle="modal" class="d-none" data-bs-target="#tree-import-modal" id="tree-import-modal-button"></button>
    <div
        class="modal fade"
        id="tree-import-modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex justify-content-around">
              <h5 class="modal-title" id="exampleModalLabel">
                <span>{{ $t("tree.import") }} </span>
              </h5>
            </div>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
            ></button>
          </div>
          <div class="modal-body">
            <table
                style="margin-top: 29px"
                class="table"
                :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            >
              <thead>
              <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
              >
                <th scope="col">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" v-model="checkAllStatus" @click="checkAll()" id="all">
                  </div>
                </th>
                <th scope="col">{{ $t("tree.account_name_en") }}</th>
                <th scope="col">{{ $t("tree.account_name") }}</th>
                <th scope="col">{{ $t("tree.code") }}</th>
                <th scope="col">{{ $t("tree.type") }}</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  :title="account.level==0?$t('tree.level_one'):account.level==1?$t('tree.level_two'):account.level==2?$t('tree.level_three'):$t('tree.level_four')"
                  :class="{'tree-level-zero':(account.level==0),'tree-level-one':(account.level==1),'tree-level-two':(account.level==2),'tree-level-three':(account.level==3)}"
                  v-for="account in imported_tree"
                  :key="account"
              >
                <td scope="col">
                  <div class="form-check" v-if="!account.exists || !account.same">
                    <input type="checkbox" class="form-check-input" v-model="account.checked">
                  </div>
                </td>
                <td scope="col">{{ account?.name_en }}</td>
                <td scope="col">{{ account?.name }}</td>
                <td scope="col">{{ account.code }}</td>
                <td scope="col">{{ account.type }}</td>
                <td scope="col">
                  {{account.exists && account.same ? 'No Change' : account.exists ? 'Edited' : "New"}}
                </td>
              </tr>
              </tbody>
            </table>
            <button :title="!showImportButton?$t(''):''" class="btn btn-success" @click="importTree()">Import</button>
          </div>
        </div>
      </div>
    </div>
    <!--End Tree Import Modal-->
  </Layout>

</template>
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import TreeBuilder from "@/services/treebuilder";


export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
   data(){
     return {
       checkAllStatus:false,
        branch_id:1,
       account_data:{},
       edit_data:{},
        chart_data:[],
       table_data:[],
       imported_tree:[],
       imported_tree_by_children:[]
     }
   },
  methods:{
    checkAll(){
      this.imported_tree.filter(account=>!account.exists || !account.same).forEach(account=>account.checked = !this.checkAllStatus)
    },
    deleteAccount(account){
      this.popup.confirm({
        title:"tree.confirm",
        msg:"tree.delete_msg"
      }).then(resp=>{
            if(resp){
              this.http.post("level-one-chart-of-accounts/tree-delete",{
                level:account.level,
                id:account.id
              }).then(resp=>{
                console.log(resp)
                this.getTree()
              })
            }
          })
    },
    editAccount(){
      this.http.post("level-one-chart-of-accounts/tree-update",this.edit_data)
      this.getTree()
    },
    preEditAccount(account){
      this.edit_data = JSON.parse(JSON.stringify(account))
    },
    getTree(){
      this.http.post("level-one-chart-of-accounts/tree",{
        branch_id:this.branch_id
      })
          .then(resp=>{
            this.table_data = resp.table_data
            var inputs=[
              {
                placeholder:"Arabic Name",
                type:"text",
                class:"form-control",
                value:""
              },
              {
                placeholder:"English Name",
                type:"text",
                class:"form-control",
                value:""
              },
              {
                placeholder:"Code",
                type:"text",
                class:"form-control",
                value:""
              }
            ]
            this.chart_data = resp.chart_data
            window.x = new TreeBuilder("#svg",{
              data:this.chart_data,
              buttons:[],
              inputs,
              maxLevels:3,
              onSave:(node,callback)=>{
                console.log("onSave",node)
                let tmp = {}
                tmp.id = node.id
                tmp.level = node.level
                if(node.level==1)
                  tmp.branch = this.branch_id
                tmp.name = node.inputs.find(input=>input.placeholder=='Arabic Name').value
                tmp.name_en = node.inputs.find(input=>input.placeholder=='English Name').value
                tmp.code = node.inputs.find(input=>input.placeholder=='Code').value
                this.http.post("level-one-chart-of-accounts/add",tmp)
                    .then(resp=>{
                      console.log("resp",resp)
                      callback(resp.id)
                    })
              },
              onDelete:(node,callback)=>{
                console.log(node)
                console.log(callback)
                if(node.level==0){
                  this.popup.alert({
                    title:"Error",
                    msg:"You cannot remove elements from level 1"
                  })
                }
                else{
                  this.popup.confirm({
                    title:"Confirmation",
                    msg:`sure to delete ${node.inputs[0].value} ?`
                  })
                      .then(resp=>{

                        if(resp) {
                          console.log("Yes")
                         // window.$loading.show()
                          this.http.post("level-one-chart-of-accounts/tree-delete",{
                            level:node.level,
                            id:node.id
                          }).then(resp=>{
                            console.log(resp)
                            console.log(callback)
                            callback()
                          })

                        }

                      })
                }
            },
              onUpdate:(node)=>{
                this.popup.confirm({
                  title:"Confirmation",
                  msg:`Sure to update ?`
                })
                    .then(resp=>{
                      if(resp) {
                        console.log("Yes")
                        let tmp = {}
                        tmp.id = node.id
                        tmp.level = node.level
                        tmp.name = node.inputs.find(input=>input.placeholder=='Arabic Name').value
                        tmp.name_en = node.inputs.find(input=>input.placeholder=='English Name').value
                        tmp.code = node.inputs.find(input=>input.placeholder=='Code').value
                        this.http.post("level-one-chart-of-accounts/tree-update",tmp)

                      }

                    })
            },
              onExport:this.exportCOA
            })

          })
    },
    addAccount(){
      let tmp = {}
      tmp.id = !this.account_data.level_two ? this.account_data.level_one.id : !this.account_data.level_three ? this.account_data.level_two.id : this.account_data.level_three.id
      tmp.level = !this.account_data.level_two ? 1 : !this.account_data.level_three ? 2 : 3
      if(tmp.level==1)
        tmp.branch = this.branch_id
      tmp.name = this.account_data.name
      tmp.name_en = this.account_data.name_en
      tmp.code = this.account_data.code
      this.http.post("level-one-chart-of-accounts/add",tmp)
          .then(resp=>{
            console.log("resp",resp)
            this.getTree()
          })

    },
    importTree(){
      document.querySelector("#tree-import-modal .btn-close").click()
      this.http.post("level-one-chart-of-accounts/import-tree",{
        branch:this.branch_id,
        data:this.imported_tree_by_children
      }).then(()=>{
        this.popup.alert({
          title:"SUUUC SSS",
          msg:"SDFSD  sdfsdf"
        })
        this.getTree()
      })
    },
    exportCOA(){
      this.http.download("level-one-chart-of-accounts/tree/download",`COA - ${(this.branch_id==1?'Kassala':this.branch_id==2?'Al-Girba':"Halfa")}.xlsx`,{
        branch_id:this.branch_id
      })
    },
    readTree(){
      var fileInput = document.querySelector("#read-tree")
      let uploadFile = event=>{
        console.log("11")

        fileInput.removeEventListener("change",uploadFile,false)
        this.http.do("level-one-chart-of-accounts/read-tree",{
          excel:event.target.files[0],
          branch:this.branch_id
        }).then(resp=>{
          var data = []

          resp.treeData.forEach(levelOne=>{
            levelOne.level = 0
            data.push(levelOne)
            if(levelOne.children && levelOne.children.length>0){
              levelOne.children.forEach(levelTwo=>{
                levelTwo.type = levelOne.type
                levelTwo.level = 1
                data.push(levelTwo)
                if(levelTwo.children && levelTwo.children.length>0){
                  levelTwo.children.forEach(levelThree=>{
                    levelThree.type = levelOne.type
                    levelThree.level = 2
                    data.push(levelThree)
                    if(levelThree.children && levelThree.children.length>0){
                      levelThree.children.forEach(levelFour=>{
                        levelFour.type = levelOne.type
                        levelFour.level = 3
                        data.push(levelFour)
                      })
                    }
                  })
                }
              })
            }
          })
          this.imported_tree = data
          this.imported_tree_by_children = resp.treeData
          document.querySelector("#tree-import-modal-button").click()

        })
        setTimeout(()=>fileInput.value = null,500)
      }
      fileInput.addEventListener("change",uploadFile,false)
      fileInput.click()

    }
  },
  computed:{
    showImportButton(){
      return this.imported_tree.find(account=>account.checked)
    }
  },
  mounted() {
    this.getTree()
  }
}
</script>
